import { extendTheme } from '@chakra-ui/react'
const theme = {
    colors: {
        primary: 'rebeccapurple',
    },
    styles: {
        global: {
            'html, body': {
                backgroundColor: "gray.800",
                color: "whiteAlpha.900"
            }
        },
    },
}

export default extendTheme(theme)